import React from "react";
import Button from "~/components/button";
import ContentWrapper from "~/components/content-wrapper";
import H1 from "~/components/Typography/H1";
import LessonLayout from "~/layouts/lesson";

const Page = () => {
  return (
    <LessonLayout
      current_step={5}
      lesson={"Lesson 4"}
      color={"ms"}
      total_count={15}
      module_title={undefined}
    >
      <ContentWrapper>
        <H1 underline>Step 5</H1>

        <p>In the Stelara® commercial that you watched earlier, can you find information about both benefits and risks of the medication? Try to find 3 benefits and 3 risks. Record your ideas in your science notebook.</p>
        <p>Click on the "Transcript" button to see the written version of the commercial. Click the "Video" button if you want to watch the commercial again.</p>

        <div className="flex flex-col">
          <Button
            action="secondary"
            color="purple"
            href="https://www.ispot.tv/ad/ASVF/stelara-which-you"
            icon="external"
            title="Video"
          >
            Video
          </Button>
          <Button
            className="mt-5"
            action="secondary"
            color="purple"
            href="/files/hs/lesson_4/stelara_commercial_transcript.pdf"
            icon="download"
            title="Transcript"
          >
            Transcript
          </Button>
        </div>
      </ContentWrapper>
    </LessonLayout>
  )
}

export default Page;

